import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './index.css';
import HomePage from './components/HomePage';
import UserSelectionPage from './components/UserSelectionPage';
import DeploymentPage from './components/DeploymentPage';
import LabInteractionPage from './components/LabInteractionPage';
import SubmissionPage from './components/SubmissionPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import PodPage from './components/PodPage';
import AdminPage from './components/AdminPage';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<UserSelectionPage />} />
        <Route path="/deploy" element={<DeploymentPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/lab" element={<LabInteractionPage />} />
        <Route path="/submit" element={<SubmissionPage />} />
        <Route path="/pods" element={<PodPage />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  </React.StrictMode>
);













