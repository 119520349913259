import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { Container, Row, Col, Card, Modal, Tabs, Tab } from 'react-bootstrap';
import ChallengeLabs from './ChallengeLabs';
import AssessmentLabs from './AssessmentLabs';
import PodLabs from './PodLabs';
import { fetchUserProfile, clearLabSessionData } from './sessionUtils';
import Header from './Header';
import UserProfile from './UserProfile';

const HomePage = () => {
  const [userData, setUserData] = useState(null);
  const [userPermissions, setUserPermissions] = useState([]);
  const [labHistory, setLabHistory] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [activeLabData, setActiveLabData] = useState(null);
  const [selectedLabType, setSelectedLabType] = useState('challenge');
  const navigate = useNavigate();

  useEffect(() => {
    console.log('HomePage mounted. Initializing...');
    const idToken = localStorage.getItem('idToken');
    if (idToken) {
      console.log('ID Token found:', idToken);
      if (idToken.split('.').length === 3) {
        try {
          const decodedToken = jwtDecode(idToken);
          console.log('Decoded token:', decodedToken);
          setUserData(decodedToken);
          checkActiveLab(decodedToken.sub);
        } catch (error) {
          console.error('Error decoding token:', error);
          localStorage.removeItem('idToken');
          navigate('/');
        }
      } else {
        console.error('Invalid token format:', idToken);
        localStorage.removeItem('idToken');
        navigate('/');
      }
    } else {
      console.warn('No ID Token found. Redirecting to login...');
      navigate('/');
    }
}, [navigate]); 

// Run fetchLabHistory only when `userPermissions` are available
useEffect(() => {
    if (userData?.sub && userPermissions.length > 0) {
        console.log('✅ Fetching lab history only after permissions are available...');
        fetchLabHistory(userData.sub, localStorage.getItem('idToken'));
    }
}, [userData?.sub, userPermissions]);

  const fetchLabHistory = async (userId, idToken) => {
    try {
        console.log('Fetching lab history for user:', userId);
        sessionStorage.removeItem('labHistory'); // Clear stale history
        const userProfile = await fetchUserProfile(userId, idToken);
        console.log('User profile fetched:', userProfile);

        const history = userProfile?.labHistory || [];
        console.log('Raw lab history:', history);
        console.log("User Permissions:", userProfile.permissions);


        const resolvedLabStatus = {};
        history.forEach((lab) => {
            const labName = lab.LabName;
            if (!resolvedLabStatus[labName] || lab.Passed) {
                resolvedLabStatus[labName] = { ...lab, Status: lab.Passed ? 'Passed' : lab.Status };
            } else if (!lab.Passed && lab.Timestamp > resolvedLabStatus[labName].Timestamp) {
                resolvedLabStatus[labName] = lab;
            }
        });

        const mergedLabHistory = Object.values(resolvedLabStatus);
        console.log('Resolved Lab History:', mergedLabHistory);
        setLabHistory(mergedLabHistory);
        sessionStorage.setItem('labHistory', JSON.stringify(mergedLabHistory));

        console.log("✅ Retrieved user permissions from Header:", userPermissions);

    } catch (error) {
        console.error('Error fetching lab history:', error);
    }
};

  const checkActiveLab = async (userSessionId) => {
    const idToken = localStorage.getItem('idToken');
    console.log('Checking active lab for session:', userSessionId);
    try {
      const response = await axios.get(`${process.env.REACT_APP_CHECK_ACTIVE_LAB_API}`, {
        params: { UserSessionId: userSessionId },
        headers: { Authorization: `Bearer ${idToken}` },
      });
      console.log('Active lab response:', response.data);

      if (response.data.activeLab) {
        setActiveLabData(response.data);
        console.log('Active lab found:', response.data);
        setShowModal(true);

        // Store session data in sessionStorage
        Object.entries(response.data).forEach(([key, value]) =>
          sessionStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : value)
        );
      } else {
        console.warn('No active lab found.');
        clearLabSessionData();
      }
    } catch (error) {
      console.error('Error checking active lab:', error);
      clearLabSessionData();
    }
  };

  const handleContinueLab = async () => {
    if (!activeLabData) {
      console.warn("No active lab data available. Aborting continue action.");
      return;
    }
  
    console.log("Continuing lab with data:", activeLabData);
    const pollingApiUrl = process.env.REACT_APP_POLLING_LAMBDA_API;
    const idToken = localStorage.getItem("idToken");
  
    // Use fallback to retrieve userSessionId
    const userSessionId = activeLabData.userSessionId || localStorage.getItem("userSessionId");
  
    // Log retrieved session ID
    console.log("Retrieved UserSessionId:", userSessionId);
  
    if (!userSessionId) {
      console.error("UserSessionId is missing. Cannot proceed.");
      alert("An error occurred while retrieving session information. Please try again.");
      return;
    }
  
    try {
      // Log the parameters being sent to the Polling Lambda
      console.log("Polling Lambda request parameters:", {
        UserSessionId: userSessionId,
        LabName: activeLabData.labName,
        Lab: activeLabData.lab,
      });
  
      if (activeLabData.stackStatus !== "CREATE_COMPLETE") {
        console.log("Polling stack status for lab:", activeLabData.labName);
  
        const response = await axios.get(pollingApiUrl, {
          params: {
            UserSessionId: userSessionId,
            LabName: activeLabData.labName,
            Lab: activeLabData.lab,
          },
          headers: { Authorization: `Bearer ${idToken}` },
        });
  
        const data = response.data;
        console.log("Polling response:", data);
  
        if (data.stackStatus === "CREATE_IN_PROGRESS") {
          alert("Lab deployment is still in progress. Please wait a moment before proceeding.");
          return;
        } else if (data.stackStatus !== "CREATE_COMPLETE") {
          alert("Unexpected error occurred. Please try again.");
          return;
        }
      }
  
      navigate("/deploy", {
        state: {
          lab: activeLabData.lab,
          labName: activeLabData.labName,
          title: activeLabData.labTitle,
          consoleUrl: activeLabData.consoleUrl,
          username: activeLabData.username,
          password: activeLabData.password,
          companyWebsite: activeLabData.companyWebsite,
          endTime: activeLabData.endTime,
          userSessionId: userSessionId, // Include session ID
        },
      });
    } catch (error) {
      console.error("Error checking lab status:", error);
      alert("Error checking lab status. Please try again.");
    }
  }; 

  const handleModalClose = () => {
    console.log('Modal closed.');
    setShowModal(false);
    setActiveLabData(null);
  };

  const handleStartLab = async (labType, labName, labTitle, estimatedTime) => {
    console.log('Starting new lab:', { labType, labName, labTitle, estimatedTime });
    const idToken = localStorage.getItem('idToken');
    const userSessionId = localStorage.getItem('userSessionId');

    try {
      const response = await axios.get(`${process.env.REACT_APP_CHECK_ACTIVE_LAB_API}`, {
        params: { UserSessionId: userSessionId },
        headers: { Authorization: `Bearer ${idToken}` },
      });

      console.log('Active lab response during start:', response.data);
      if (response.data.activeLab) {
        setActiveLabData(response.data);
        setShowModal(true);
      } else {
        clearLabSessionData();
        navigate('/deploy', { state: { lab: labType, labName, title: labTitle, estimatedTime } });
      }
    } catch (error) {
      console.error('Error starting new lab:', error);
      clearLabSessionData();
      navigate('/deploy', { state: { lab: labType, labName, title: labTitle, estimatedTime } });
    }
  };

  console.log("HomePage User Permissions:", userPermissions);


  return (
    <>
      <Header setUserPermissions={setUserPermissions} userPermissions={userPermissions} />
      <Container style={{ marginTop: '2rem', paddingBottom: '2rem' }}>

        <Modal show={showModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Active Lab Found</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You have an ongoing lab session. Do you want to continue where you left off?
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-primary" onClick={handleContinueLab}>
              Continue Lab
            </button>
          </Modal.Footer>
        </Modal>

        <Row className="justify-content-center">
          <Col md={12}>
            <Card className="text-center shadow-sm" style={{ backgroundColor: '#FFFFFF' }}>
              <Card.Body>
                <Tabs
                  activeKey={selectedLabType}
                  onSelect={(tabKey) => setSelectedLabType(tabKey)}
                  className="mb-4"
                >
                  <Tab eventKey="challenge" title="Challenge Labs">
                  <ChallengeLabs handleStartLab={handleStartLab} userPermissions={userPermissions} />
                  </Tab>
                  <Tab eventKey="assessment" title="Assessment Labs">
                  <AssessmentLabs handleStartLab={handleStartLab} userPermissions={userPermissions} />
                  </Tab>
                  <Tab eventKey="pod" title="Pod Collaboration">
                    <PodLabs />
                  </Tab>
                </Tabs>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default HomePage;
