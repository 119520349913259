import React, { useEffect, useState } from 'react';
import LabCard from './LabCard';
import { fetchUserProfile, useUserSession } from './sessionUtils';
import permissionsConfig from "../config/permissions.json";
import axios from 'axios';

// Static lab metadata
const staticLabMetadata = [
  {
    LabName: 's3-website-assessment',
    Title: 'S3 Static Website Assessment',
    Description: 'Build an S3 Static Website based on given specifications and make the website publicly accessible.',
    EstimatedTime: 30,
    IsActive: true,
  },
  {
    LabName: 'ssh-assessment',
    Title: 'SSH Assessment',
    Description: 'Deploy an EC2 instance and SSH remotely.',
    EstimatedTime: 165,
    IsActive: true,
  },
  {
    LabName: 'apache-web-server-assessment',
    Title: 'Apache Web Server Installation',
    Description: 'Install Apache on a Linux EC2.',
    EstimatedTime: 45,
    IsActive: true,
  },
  {
    LabName: 'bootstrap-apache-assessment',
    Title: 'Bootstrap Apache Web Server',
    Description: 'Bootstrapping your Apache Installation on Linux EC2.',
    EstimatedTime: 45,
    IsActive: true,
  },
  {
    LabName: 'linux-permissions-assessment',
    Title: 'Linux Permission Management',
    Description: 'Create a directory structure and modify user permissions.',
    EstimatedTime: 45,
    IsActive: true,
  },
  {
    LabName: 'vpc-autoscaling-assessment',
    Title: 'VPC Networking and AutoScaling with Load Balancing',
    Description: 'Design and implement a custom VPC with an autoscaling group to ensure scalability and distribute traffic efficiently using a load balancer.',
    EstimatedTime: 30,
    IsActive: false,
  },
  {
    LabName: 'cloudformation-assessment',
    Title: 'Infrastructure as Code using CloudFormation',
    Description: 'Use CloudFormation to automate the deployment of an EC2 instance.',
    EstimatedTime: 60,
    IsActive: false,
  },
  {
    LabName: 'two-tier-assessment',
    Title: 'Two Tier Architecture',
    Description: 'Deploy a two tier architecture with proper frontend and backend configuration.',
    EstimatedTime: 60,
    IsActive: false,
  },
];

const AssessmentLabs = ({ handleStartLab, userPermissions }) => {
  const [labs, setLabs] = useState([]);
  const [error, setError] = useState(null);
  const { idToken, sub } = useUserSession();

  // Fetch lab data and merge with static metadata
  useEffect(() => {
    const fetchLabs = async () => {
      try {
        const userProfile = await fetchUserProfile(sub, idToken);
        const userLabs = userProfile?.labHistory || [];
  
        // Merge static metadata with user lab history
        const mergedLabs = staticLabMetadata.map((staticLab) => {
          const userLabAttempts = userLabs.filter((lab) => lab.LabName === staticLab.LabName);
        
          // Find the latest Passed attempt, if any
          const passedAttempt = userLabAttempts.find((lab) => lab.Passed);
        
          // If no Passed attempt exists, find the latest attempt by Timestamp
          const latestAttempt = userLabAttempts.reduce(
            (latest, current) =>
              new Date(current.Timestamp) > new Date(latest.Timestamp) ? current : latest,
            { Timestamp: '1970-01-01T00:00:00.000Z' }
          );
        
          // Prioritize Passed attempt if available, otherwise fallback to the latest attempt
          const resolvedAttempt = passedAttempt || latestAttempt;
  
          // Check if user has permission for this lab
          const userHasAccess = userPermissions?.some(permission => 
            permissionsConfig[permission]?.assessmentLabs?.includes(staticLab.LabName)
          );
  
          console.log("Checking permissions for", staticLab.LabName);
          console.log("User has permissions:", userPermissions);
          console.log("Allowed labs in permissions.json:", permissionsConfig);
  
        
          return {
            ...staticLab,
            Status: resolvedAttempt.Status || 'Not Attempted',
            Passed: resolvedAttempt.Passed || false,
            IsActive: userHasAccess // Lock/disable if no access
          };
        });
  
        setLabs(mergedLabs);
      } catch (err) {
        console.error('Error fetching labs:', err);
        setError('Unable to fetch lab information.');
      }
    };
  
    fetchLabs();
  }, [idToken, sub, userPermissions]); 

  // Track lab start
  const handleStartLabWithTracking = async (labType, labName, labTitle, estimatedTime) => {
    const idToken = localStorage.getItem('idToken');
    const userSessionId = localStorage.getItem('userSessionId');
    const username = localStorage.getItem('username');
    const userId = userSessionId;

    try {
      await axios.post(
        process.env.REACT_APP_TRACK_LAB_START_API,
        {
          UserId: userId,
          UserSessionId: userSessionId,
          Username: username,
          LabName: labName,
          LabType: labType,
          Action: 'start_lab',
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
    } catch (error) {
      console.error('Error tracking lab start:', error);
    }

    handleStartLab(labType, labName, labTitle, estimatedTime);
  };

  return (
    <div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {labs.map((lab, index) => (
        <LabCard
          key={index}
          title={lab.Title}
          description={lab.Description}
          buttonText={lab.IsActive ? "Start Assessment" : "🔒 Locked"}
          handleClick={() => lab.IsActive && handleStartLabWithTracking(
              'assessment',
              lab.LabName,
              lab.Title,
              lab.EstimatedTime
            )
          }
          isActive={lab.IsActive}
          status={lab.Status}
          passed={lab.Passed}
          labType="assessment" // Explicitly pass labType
        />
      ))}
    </div>
  );
};

export default AssessmentLabs;
