import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Container, Table, Form, Button, Alert, Spinner, Modal, Pagination } from "react-bootstrap";
import { FaEdit, FaHistory, FaUserSlash, FaUserCheck, FaSort, FaSortUp, FaSortDown } from "react-icons/fa"; 
import permissionsConfig from "../config/permissions.json";
import Header from "./Header";
import { fetchUserProfile } from "./sessionUtils"; // Import fetchUserProfile

const AdminPage = () => {
    const [users, setUsers] = useState([]); // ✅ Fix missing state
    const [sortConfig, setSortConfig] = useState({ key: "name", direction: "asc" });
    const [availablePermissions, setAvailablePermissions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 10;
    const [selectedUser, setSelectedUser] = useState(null);
    const [showPermissionModal, setShowPermissionModal] = useState(false);
    const [labHistory, setLabHistory] = useState([]);
    const [showLabHistoryModal, setShowLabHistoryModal] = useState(false);
    const [loadingLabHistory, setLoadingLabHistory] = useState(false);
    const navigate = useNavigate();

    const API_GET_USERS = process.env.REACT_APP_GET_USER_PERMISSIONS_API;
    const API_UPDATE_USERS = process.env.REACT_APP_UPDATE_USER_PERMISSIONS_API;
    const API_DISABLE_USER = process.env.REACT_APP_DISABLE_USER_API;
    const API_GET_LAB_HISTORY = process.env.REACT_APP_GET_LAB_HISTORY_API;

    useEffect(() => {
        if (permissionsConfig) {
            setAvailablePermissions(Object.keys(permissionsConfig));
        }
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const idToken = localStorage.getItem("idToken"); // ✅ Get JWT token
                if (!idToken) {
                    throw new Error("User is not authenticated.");
                }
    
                const response = await axios.get(API_GET_USERS, {
                    headers: { Authorization: `Bearer ${idToken}` }, // ✅ Include token
                });
    
                if (response.status === 200) {
                    setUsers(response.data);
                } else {
                    setError("Failed to fetch users");
                }
            } catch (err) {
                if (err.response?.status === 401) {
                    alert("Session expired. Please log in again.");
                    navigate("/login");
                } else if (err.response?.status === 403) {
                    alert("You do not have permission to view this page.");
                    navigate("/home");
                } else {
                    setError("Error fetching users");
                    console.error("Error:", err);
                }
            } finally {
                setLoading(false);
            }
        };
        fetchUsers();
    }, [navigate]);
    

    const handleFetchLabHistory = async (userId) => {
        setLoadingLabHistory(true);
        setShowLabHistoryModal(true);
    
        try {
            const idToken = localStorage.getItem("idToken"); // Ensure user session is valid
            if (!idToken) throw new Error("User is not authenticated.");
    
            console.log('Fetching lab history for UserID: ${userId}');
    
            // Use the same approach as AssessmentLabs.js & ChallengeLabs.js
            const userProfile = await fetchUserProfile(userId, idToken);
            console.log("User profile data:", userProfile);
    
            const userLabs = userProfile?.labHistory || [];
    
            if (Array.isArray(userLabs)) {
                // Reduce the array to ensure only one entry per LabName, prioritizing Passed
                const uniqueLabHistory = Object.values(
                    userLabs.reduce((acc, lab) => {
                        const labName = lab.LabName;
                        if (!acc[labName] || (!acc[labName].Passed && lab.Passed)) {
                            acc[labName] = lab; // Prioritize Passed
                        }
                        return acc;
                    }, {})
                );
            
                setLabHistory(uniqueLabHistory);
            } else {
                console.error("Unexpected lab history format:", userLabs);
                setLabHistory([]);
            }
        } catch (error) {
            console.error("Error fetching lab history:", error);
            setLabHistory([]);
        } finally {
            setLoadingLabHistory(false);
            setShowLabHistoryModal(true);
        }
    };    

    const handleToggleUserStatus = async (userId, isDisabled) => {
        try {
            const idToken = localStorage.getItem("idToken");
            if (!idToken) throw new Error("User is not authenticated.");
    
            const response = await axios.post(
                API_DISABLE_USER,
                { UserId: userId, disabled: !isDisabled },
                { headers: { Authorization: `Bearer ${idToken}` } } // ✅ Include token
            );
    
            if (response.status === 200) {
                alert(`User ${!isDisabled ? "disabled" : "enabled"} successfully!`);
                setUsers((prevUsers) =>
                    prevUsers.map((user) =>
                        user.UserId === userId ? { ...user, disabled: !isDisabled } : user
                    )
                );
            } else {
                throw new Error("Unexpected response status: " + response.status);
            }
        } catch (error) {
            if (error.response?.status === 401) {
                alert("Session expired. Please log in again.");
                navigate("/login");
            } else {
                console.error("Error toggling user status:", error);
                alert("Failed to update user status.");
            }
        }
    };
    

    const handleOpenPermissions = (user) => {
        setSelectedUser(user);
        setShowPermissionModal(true);
    };

    const handlePermissionChange = (permission) => {
        setSelectedUser((prevUser) => ({
            ...prevUser,
            permissions: prevUser.permissions.includes(permission)
                ? prevUser.permissions.filter((p) => p !== permission)
                : [...prevUser.permissions, permission],
        }));
    };

    const handleSavePermissions = async () => {
        setSaving(true);
        try {
            const idToken = localStorage.getItem("idToken");
            if (!idToken) throw new Error("User is not authenticated.");
    
            await axios.post(
                API_UPDATE_USERS,
                { UserId: selectedUser.UserId, permissions: selectedUser.permissions },
                { headers: { Authorization: `Bearer ${idToken}` } } // ✅ Include token
            );
    
            setUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.UserId === selectedUser.UserId ? { ...user, permissions: selectedUser.permissions } : user
                )
            );
    
            alert("Permissions updated successfully!");
            setShowPermissionModal(false);
        } catch (error) {
            if (error.response?.status === 401) {
                alert("Session expired. Please log in again.");
                navigate("/login");
            } else {
                console.error("Error updating permissions:", error);
                alert("Failed to update permissions.");
            }
        } finally {
            setSaving(false);
        }
    };
    

    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const sortedUsers = [...filteredUsers].sort((a, b) => {
        if (a[sortConfig.key].toLowerCase() < b[sortConfig.key].toLowerCase()) {
            return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (a[sortConfig.key].toLowerCase() > b[sortConfig.key].toLowerCase()) {
            return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
    });
    
    const handleSort = (key) => {
        setSortConfig(prevConfig => {
            let direction = "asc";
            if (prevConfig.key === key && prevConfig.direction === "asc") {
                direction = "desc";
            }
            return { key, direction };
        });
    };
    
    const getSortIcon = (key) => {
        if (sortConfig.key !== key) return <FaSort />;
        return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />;
    };

    return (
        <>
            <Header />
            <Container>
                <h2 className="mt-4">Admin Panel - User Permissions</h2>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form.Control type="text" placeholder="Search by name or email" className="mb-3" onChange={(e) => setSearchQuery(e.target.value.toLowerCase())} />
                {loading ? (
                    <Spinner animation="border" />
                ) : (
                    <>
                        <Table striped bordered hover className="mt-3">
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort("name")} style={{ cursor: "pointer" }}>
                                        Name {getSortIcon("name")}
                                    </th>
                                    <th onClick={() => handleSort("email")} style={{ cursor: "pointer" }}>
                                        Email {getSortIcon("email")}
                                    </th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedUsers.map((user) => (
                                    <tr key={user.UserId}>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>
                                            <Button variant="info" size="sm" onClick={() => handleOpenPermissions(user)}>
                                                <FaEdit /> Edit Permissions
                                            </Button>
                                            <Button variant={user.disabled ? "success" : "danger"} size="sm" className="ms-2" onClick={() => handleToggleUserStatus(user.UserId, user.disabled)}>
                                                {user.disabled ? <><FaUserCheck /> Enable User</> : <><FaUserSlash /> Disable User</>}
                                            </Button>
                                            <Button variant="secondary" size="sm" className="ms-2" onClick={() => handleFetchLabHistory(user.UserId)}>
                                                <FaHistory /> View Lab History
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        
                        <Modal show={showPermissionModal} onHide={() => setShowPermissionModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Permissions - {selectedUser?.name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {availablePermissions.map((perm) => (
                                    <Form.Check
                                        key={perm}
                                        type="checkbox"
                                        label={perm}
                                        checked={selectedUser?.permissions.includes(perm)}
                                        onChange={() => handlePermissionChange(perm)}
                                    />
                                ))}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowPermissionModal(false)}>Close</Button>
                                <Button variant="primary" onClick={handleSavePermissions} disabled={saving}>
                                    {saving ? "Saving..." : "Save Changes"}
                                </Button>
                            </Modal.Footer>
                        </Modal>

                        {/* NEW: Modal for displaying lab history */}
                        <Modal show={showLabHistoryModal} onHide={() => setShowLabHistoryModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Lab History - {selectedUser?.name}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {loadingLabHistory ? (
                                    <Spinner animation="border" />
                                ) : labHistory.length > 0 ? (
                                    <ul>
                                        {labHistory.map((lab, index) => (
                                            <li key={index}>{lab.LabName} - {lab.Passed ? '✅ Passed' : '❌ Failed'}</li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No lab history available.</p>
                                )}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowLabHistoryModal(false)}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                    </>
                )}
            </Container>
        </>
    );
};

export default AdminPage;
