// UserProfile.js
import React, { useEffect, useState } from 'react';
import { fetchUserProfile, useUserSession } from './sessionUtils'; // Import required functions
import { Dropdown } from 'react-bootstrap'; // Import Dropdown for user profile display
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons CSS

const UserProfile = ({ setUserPermissions }) => {
    const { idToken, sub } = useUserSession();
    const [userProfile, setUserProfile] = useState(null);


    const fetchUserPermissions = async (sub, idToken) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_GET_USER_PERMISSIONS_API}?UserId=${sub}`, {
                headers: { Authorization: idToken },
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch permissions: ${response.statusText}`);
            }

            const permissionsData = await response.json();
            console.log("User permissions fetched:", permissionsData);
            return permissionsData.permissions || [];
        } catch (error) {
            console.error("Error fetching user permissions:", error);
            return [];
        }
    };

    useEffect(() => {
        if (idToken && sub && !userProfile) {
            const getUserProfile = async () => {
                try {
                    const profile = await fetchUserProfile(sub, idToken); // Fetch user profile from API
                    const permissions = await fetchUserPermissions(sub, idToken); // Fetch user permissions separately

                    if (profile) {
                        setUserProfile({ ...profile, permissions });
                        setUserPermissions(permissions);
                    }
                } catch (error) {
                    console.error('Error fetching user profile:', error);
                }
            };
            getUserProfile();
        }
    }, [idToken, sub, userProfile]);

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Dropdown align="end" className="position-relative">
              <Dropdown.Toggle
                  variant="light"
                  id="dropdown-user-profile"
                  className="user-profile-toggle no-caret"
                  style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: 'transparent',
                      border: 'none',
                      boxShadow: 'none', // Removes any unwanted shadows
                      appearance: 'none', // Ensures default dropdown styles are reset
                      paddingRight: '0px', // Ensures no extra space for caret
                  }}
                  as="button" //prevents bootstrap from auto-adding caret
              >
                  <i
                      className="bi bi-person-circle"
                      style={{
                          fontSize: '1.8rem',
                          cursor: 'pointer',
                          color: '#FFFFFF',
                          marginRight: '0.5rem',
                      }}
                  ></i>
                  {userProfile && (
                      <span style={{ color: '#FFFFFF', fontWeight: 'bold' }}>
                          {userProfile.name || 'User'}
                      </span>
                  )}
              </Dropdown.Toggle>


              <Dropdown.Menu style={{ right: 0, left: 'auto' }}>
                  {userProfile ? (
                      <>
                          <Dropdown.ItemText>
                              <strong>Name:</strong> {userProfile.name}
                          </Dropdown.ItemText>
                          <Dropdown.ItemText>
                              <strong>Email:</strong> {userProfile.email}
                          </Dropdown.ItemText>
                          <Dropdown.ItemText>
                              <strong>Role:</strong> {userProfile.role}
                          </Dropdown.ItemText>
                          <Dropdown.ItemText>
                              <strong>Permissions:</strong> {userProfile.permissions?.join(", ") || "None"}
                          </Dropdown.ItemText>
                          <Dropdown.Divider />
                          <Dropdown.Item
                              onClick={() => {
                                  localStorage.removeItem('idToken');
                                  window.location.href = '/login';
                              }}
                          >
                              Sign Out
                          </Dropdown.Item>
                      </>
                  ) : (
                      <Dropdown.ItemText>Loading profile...</Dropdown.ItemText>
                  )}
              </Dropdown.Menu>
          </Dropdown>

        </div>
    );
};

export default UserProfile;
