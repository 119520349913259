// components/Header.js

import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { GearFill } from 'react-bootstrap-icons'; // Bootstrap gear icon
import LUITLogo from './assets/LUIT_logo.png'; // Adjust path if necessary
import UserProfile from './UserProfile';

const Header = ({ setUserPermissions, userPermissions }) => {
  const navigate = useNavigate();

  console.log("Header User Permissions:", userPermissions);

  return (
    <Navbar
      expand="lg"
      style={{
        backgroundColor: '#1A1B54',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        padding: '0.5rem 1rem',
      }}
    >
      <Container>
        {/* Logo and Title */}
        <Navbar.Brand
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => navigate('/home')}
        >
          <img
            src={LUITLogo}
            alt="Level Up In Tech Logo"
            style={{ width: '50px', marginRight: '10px' }}
          />
          <span style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '1.5rem' }}>
            Level Up In Tech Labs
          </span>
        </Navbar.Brand>

        {/* Admin Gear Icon & User Profile */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          {/* Show Gear Icon if User is Admin */}
          {userPermissions?.includes('admin') && (
            <GearFill
              style={{ fontSize: '1.5rem', color: '#FFFFFF', cursor: 'pointer' }}
              onClick={() => navigate('/admin')}
              title="Admin Panel"
            />
          )}

          {/* User Profile */}
          <UserProfile setUserPermissions={setUserPermissions} />
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;

